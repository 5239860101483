import React, {
	useCallback, useEffect, useMemo, useState
} from 'react';
import Select from 'react-select';
import createFetch from '../../utils/create-fetch';
import TemplatedSelectPickerMultiValue from './TemplatedSelectPickerMultiValue';
import TemplatedSelectPickerOption from './TemplatedSelectPickerOption';
import TemplatedSelectPickerSingleValue from './TemplatedSelectPickerSingleValue';
import useExternalEvents from './hooks/useExternalEvents';
import TemplatedSelectPickerMultiValueRemove from './TemplatedSelectPickerMultiValueRemove';

export default function TemplatedSelectPicker(element) {
	const {
		node: {
			id,
			name,
			children,
			dataset,
			multiple,
			selectedOptions
		}
	} = element;

	const [inputValue, setInputValue] = useState(null);
	const [queriedOptions, setQueriedOptions] = useState(null);
	const [selected, setSelected] = useState(null);
	const [selectOptions, setSelectOptions] = useState([]);

	const {
		externalChangeEventValues, updateOptionsEventValues, addAndSelectEventValue, dispatchValueChangedEvent, isDisabledEventValue, setIsDisabledEventValue
	} = useExternalEvents(id);

	const onValueChange = useCallback(newValues => {
		setSelected(prevState => {
			let result = newValues;

			if(dataset.uniqueOptionValue) {
				const uniqueOption = newValues.find(({ value }) => value === dataset.uniqueOptionValue);
				if(uniqueOption) {
					// disable all other options
					setSelectOptions(selectOptions.map(selectOption => ({
						...selectOption,
						isDisabled: selectOption.value !== dataset.uniqueOptionValue
					})));
					// select only the unique options
					result = [uniqueOption];
				} else if(prevState.find(({ value }) => value === dataset.uniqueOptionValue)) {
					// enable all options
					setSelectOptions(selectOptions.map(selectOption => ({
						...selectOption,
						isDisabled: false
					})));
				}
			}

			if(dataset.selectAll) {
				const sAllValue = dataset.selectAllValue ?? 'SELECT_ALL';
				const allOptionSelected = newValues?.find(({ value }) => value === sAllValue);

				if(allOptionSelected) {
					// select correct all options
					if(dataset.selectAllValue) {
						result = [allOptionSelected];
					} else {
						const nonAllOptions = selectOptions.filter(({ value }) => value !== sAllValue);
						// support for grouped options
						if(Array.isArray(nonAllOptions) && nonAllOptions.length > 0 && Array.isArray(nonAllOptions[0].options)) {
							result = [];
							nonAllOptions.forEach(({ options }) => {
								result = [
									...result,
									...options
								];
							});
						} else {
							result = nonAllOptions;
						}
					}
					// disable all other options
					setSelectOptions(selectOptions.map(selectOption => {
						if(selectOption.options) {
							return ({
								...selectOption,
								options: selectOption.options.map(option => ({
									...option,
									isDisabled: dataset.selectAllValue && option.value !== sAllValue
								}))
							});
						}
						return ({
							...selectOption,
							isDisabled: dataset.selectAllValue && selectOption.value !== sAllValue
						});
					}));
				} else if(prevState?.find(({ value }) => value === sAllValue)) {
					// enable all options
					setSelectOptions(selectOptions.map(selectOption => ({
						...selectOption,
						isDisabled: false
					})));
				}
			}

			if(prevState !== newValues) {
				dispatchValueChangedEvent(result, prevState);
			}
			return result;
		});
	}, [selectOptions, dataset.uniqueOptionValue, dataset.selectAll]);

	const fetchOptions = useCallback(async () => {
		const params = { ...JSON.parse(dataset.queryExtraParams ?? '{}') };
		if(dataset.querySearchKey) {
			params[dataset.querySearchKey] = inputValue;
		} else {
			params.searchValue = inputValue;
		}
		const result = await createFetch({
			url: dataset.queryUrl,
			params,
			headers: dataset.queryHandle ? { 'X-Dation-Handle': dataset.queryHandle } : {}
		});
		const { data } = result;
		return data.map(item => ({
			value: item[dataset.queryValueKey] ?? item.value,
			label: item[dataset.queryLabelKey] ?? item.label
		}));
	}, [
		inputValue,
		dataset.queryHandle,
		dataset.queryValueKey,
		dataset.queryLabelKey,
		dataset.querySearchKey,
		dataset.queryExtraParams,
		dataset.queryUrl
	]);

	useEffect(() => {
		if(dataset.disabled) {
			setIsDisabledEventValue(dataset.disabled === 'true');
		}
	}, []);

	useEffect(() => {
		if(externalChangeEventValues) {
			setSelected(
				multiple
					? selectOptions.filter(({ value }) => externalChangeEventValues.find(selectedValue => `${selectedValue}` === `${value}`))
					: selectOptions.find(({ value }) => `${value}` === `${externalChangeEventValues}`)
			);
			setSelectOptions(selectOptions.map(selectOption => ({
				...selectOption,
				isDisabled: false
			})));
		}
	}, [externalChangeEventValues]);

	useEffect(() => {
		if(updateOptionsEventValues) {
			setSelectOptions(updateOptionsEventValues);
			onValueChange(updateOptionsEventValues.filter(({ isSelected }) => isSelected));
		}
	}, [updateOptionsEventValues]);

	useEffect(() => {
		if(addAndSelectEventValue) {
			setSelectOptions([...(selectOptions ?? []), addAndSelectEventValue]);
			onValueChange([...(selected ?? []), addAndSelectEventValue]);
		}
	}, [addAndSelectEventValue]);

	useEffect(() => {
		const result = [];
		if(dataset.selectAll) {
			result.push({ value: dataset.selectAllValue ?? 'SELECT_ALL', label: 'Alle' });
		}
		Array.from(children)
			.forEach(child => {
				if(child.tagName === 'OPTION') {
					result.push({
						value: child.value,
						label: child.label,
						data: child.dataset,
						isDisabled: child.dataset.disabled || dataset.defaultSelectAll === 'true'
					});
				}

				if(child.tagName === 'OPTGROUP') {
					result.push({
						label: child.label,
						options: Array.from(child.children)
							.map(option => ({
								value: option.value,
								label: option.label,
								data: option.dataset,
								isDisabled: child.dataset.disabled || dataset.defaultSelectAll === 'true'
							}))
					});
				}
			});

		setSelectOptions(result);
	}, [children, dataset.selectAllValue, dataset.defaultSelectAll]);

	useEffect(() => {
		if(dataset.queryUrl && inputValue?.length >= 3) {
			fetchOptions(inputValue)
				.then(newOptions => setQueriedOptions(newOptions));
		}
	}, [dataset.queryUrl, inputValue]);

	useEffect(() => setSelected([
		...Array.from(selectedOptions)
			.map(selectOption => ({
				value: selectOption.value,
				label: selectOption.label,
				data: selectOption.dataset
			})),
		...(dataset.defaultSelectAll ? [{
			value: dataset.selectAllValue ?? 'SELECT_ALL',
			label: 'Alle'
		}] : [])
	]), [selectedOptions, dataset]);

	const TemplatedSelectPickerMultiValueWrapper = useCallback(props => (
		<TemplatedSelectPickerMultiValue
			maxToShow={dataset.maxSelected ? parseInt(dataset.maxSelected, 10) : 3}
			{...props}
		/>
	), [dataset.maxSelected]);

	const styles = useMemo(() => ({
		multiValueRemove: baseStyles => ({
			...baseStyles,
			':hover': {
				color: 'rgb(220, 53, 69)'
			}
		}),
		multiValueLabel: baseStyles => ({
			...baseStyles,
			fontFamily: 'Proxima Nova',
			fontSize: '100%'
		}),
		control: (baseStyles, state) => ({
			...baseStyles,
			...(dataset.width ? { width: dataset.width } : {}),
			boxShadow: state.isFocused ? '0 0 0 0.25rem rgba(88, 33, 162, 0.25)' : undefined,
			'&:hover': {
				borderColor: '#ac90d1'
			},
			'&:focus': {
				borderColor: '#ac90d1'
			}
		}),
		clearIndicator: baseStyles => ({
			...baseStyles,
			':hover': {
				color: 'rgb(220, 53, 69)'
			}
		})
	}), [dataset]);

	const classNames = useMemo(() => ({
		multiValue: () => 'rounded-pill text-bg-primary',
		multiValueLabel: () => 'text-white ps-2 py-1',
		control: () => 'form-control p-0'
	}), []);

	const components = useMemo(() => ({
		MultiValue: TemplatedSelectPickerMultiValueWrapper,
		Option: TemplatedSelectPickerOption,
		SingleValue: TemplatedSelectPickerSingleValue,
		MultiValueRemove: TemplatedSelectPickerMultiValueRemove
	}), []);

	return (
		<Select
			id={id}
			instanceId={id}
			// When the multi select has no value selected, an hidden input with the name is still put in the form.
			// this breaks things in the back end, so we have to remove the name if the multi value is emptu
			name={(multiple && !selected?.length) ? '' : name}
			isDisabled={isDisabledEventValue}
			options={queriedOptions ?? selectOptions}
			value={selected}
			onChange={onValueChange}
			isMulti={multiple}
			noOptionsMessage={() => 'Geen opties beschikbaar'}
			placeholder={dataset.header || 'Selecteer'}
			isSearchable={multiple || dataset.liveSearch === 'true'}
			onInputChange={setInputValue}
			hideSelectedOptions={false}
			closeMenuOnSelect={!multiple}
			styles={styles}
			classNames={classNames}
			components={components}
			// prevents selectpicker from instantly closing after a selection on ios browser
			blurInputOnSelect={!multiple}
		/>
	);
}
